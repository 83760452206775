import Markdown from 'react-markdown';
import { memo } from 'react';
import ScriptRenderer from './ScriptRenderer';

export default memo(
  function ResponseRender({ response, onSelect, selectedScript, onChange, scriptType }) {
    let langMap = {
      ps: 'powershell'
    };
    return response.status === 'success' ? (
      response.result ? (
        <Markdown
          className="ai-reponse-viewer"
          components={{
            code(props) {
              const { children, className, node, ...rest } = props;
              const match = /language-(\w+)/.exec(className || '');
              return match ? (
                <ScriptRenderer
                  {...rest}
                  language={scriptType || langMap[match[1]] || match[1]}
                  selected={selectedScript?.script === String(children).replace(/\n$/, '')}
                  onSelect={() => {
                    onSelect({
                      script: String(children).replace(/\n$/, '')
                    });
                  }}
                  onChange={(value) => {
                    onChange(value);
                  }}>
                  {String(children).replace(/\n$/, '')}
                </ScriptRenderer>
              ) : (
                <code {...rest} className={className}>
                  {children}
                </code>
              );
            }
          }}>
          {response.result}
        </Markdown>
      ) : (
        <div className="flex items-center justify-center flex-1">
          <h3 className="">No Result Found!</h3>
        </div>
      )
    ) : (
      <div className="flex items-center justify-center flex-1">
        <h3 className="text-danger">Failed to generate script</h3>
        <h5 className="text-danger">{response.message}</h5>
      </div>
    );
  },
  function (oldProps, newProps) {
    return oldProps.response === newProps.response;
  }
);
