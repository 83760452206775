import processChartOptions from './chart-option-processor';
import buildResult from '../result-builder';
import { useEffect, useState } from 'react';
import ChartRender from './ChartRender';

export default function Chart({ widget, data, ...props }) {
  const [options, setOptions] = useState(null);
  useEffect(() => {
    const result = props.result ? props.result : buildResult(data, widget);
    const options = processChartOptions(result.series, widget, {
      categories: result.categories,
      dateTime: data['x-axis'] === 'time',
      stacked: widget.type.indexOf('Stacked') >= 0,
      ...(props.options || {})
    });
    setOptions(options);
  }, [widget, data, props.result, props.options]);

  return (
    options && (
      <div className="h-full flex flex-col min-h-0 flex-1">
        <ChartRender style={{ height: '100%' }} {...props} options={options} />
      </div>
    )
  );
}
