import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const VulnerabilityLayout = lazy(() =>
  import(/* webpackChunkName: "vulnerability" */ './layout/VulnerabilityLayout')
);
const List = lazy(() => import(/* webpackChunkName: "vulnerability" */ './views/List'));
const ManageExceptionsList = lazy(() =>
  import(/* webpackChunkName: "vulnerability" */ './views/ManageExceptionsList')
);

/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="/vulnerability" key="vulnerability" element={<AppLayout />}>
      {/* if no route is defined navigate to about route */}
      <Route element={<VulnerabilityLayout key="zerodayvulnerabilities" zeroDayVulnerability />}>
        <Route path="zero-day-vulnerabilities" element={<List zeroDayVulnerability />}></Route>
      </Route>
      <Route element={<VulnerabilityLayout key="all" />}>
        <Route index path="all" element={<List />}></Route>
        <Route path="manage-exceptions" element={<ManageExceptionsList />} />
      </Route>
    </Route>
  );
}
