import { useState } from 'react';
import { Button, Modal, Row, Col, Form, Input, Divider, Select, Popover, Switch } from 'antd';
import { marked } from 'marked';
import { generateScriptApi } from '@/src/modules/device-automation/configurations';
import PlatformPicker from '../pickers/PlatformPicker';
import Icon from '../Icon';
import SocketEventHandler from '../SocketEventHandler';
import ResponseRender from './ResponseRender';

export default function FormModal({ type, platform, script_type, onClose, onChange }) {
  let langMap = {
    ps: 'powershell'
  };
  const [processing, setProcessing] = useState(false);
  const [eventInProgress, setEventInProgress] = useState(false);
  const platformWiseScripts = {
    windows: [
      { label: type === 'command' ? 'CMD' : 'Batch File (.bat)', value: 'batch' },
      { value: 'powershell', label: type === 'command' ? 'Powershell' : 'Powershell (.ps1)' }
    ],
    mac: [{ value: 'bash', label: 'Bash (.sh)' }],
    linux: [{ value: 'bash', label: 'Bash (.sh)' }]
  };
  const [formItem, setFormItem] = useState({
    type,
    platform,
    script_type: script_type
      ? script_type
      : platform
      ? platformWiseScripts[platform][0].value
      : undefined
  });
  const [form] = Form.useForm();
  const [eventKey, setEventKey] = useState(0);
  const [popConfirmOpen, setPopConfirmOpen] = useState(false);
  const [selectedScript, setSelectedScript] = useState(null);
  const [response, setResponse] = useState(null);
  const [modifiedScript, setModifiedScript] = useState(null);

  function handleFormSubmit() {
    form.validateFields().then((values) => {
      setResponse(null);
      setFormItem(values);
      setEventInProgress(true);
      setEventKey((key) => key + 1);
    });
  }

  function handleResponseReceived(response) {
    setEventInProgress(false);
    setResponse(response);
    if (response.status === 'success') {
      marked.use({
        hooks: {
          processAllTokens(tokens) {
            let codes = (tokens || []).filter((token) => token.type === 'code' && token.lang);
            if (codes.length > 0) {
              setSelectedScript({
                script: codes[0].text.replace(/\n$/, ''),
                language: langMap[codes[0].lang] || codes[0].lang
              });
            } else {
              setSelectedScript(null);
            }
            return tokens;
          }
        }
      });

      marked.parse(response.result || '');
    }
  }

  function handleSaveScript(saveContext) {
    setProcessing(true);
    generateScriptApi(
      modifiedScript || selectedScript.script,
      selectedScript.language || formItem.script_type,
      saveContext.use_custom_name ? saveContext.name : undefined
    ).then((data) => {
      onChange([data]);
      onClose();
    });
  }

  return (
    <Modal
      open
      title={`Generate ${type === 'command' ? 'Command' : 'Script'} Using AI`}
      onCancel={() => onClose()}
      width="85%"
      destroyOnClose={true}
      footer={null}>
      <Row gutter={32} className="py-4 h-[70vh] overflow-y-auto overflow-x-hidden">
        <Col
          span={8}
          className="border-border border-r border-solid border-t-0 border-b-0 border-l-0 h-full">
          <Form layout="vertical" form={form} onFinish={handleFormSubmit} initialValues={formItem}>
            <div className="h-full flex flex-col min-h-0 flex-1">
              <div>
                <Form.Item hidden label="Type" name="type" disabled rules={[{ required: true }]}>
                  <Input placeholder="Type ex. script" disabled />
                </Form.Item>
              </div>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Platform" name="platform" rules={[{ required: true }]}>
                    <PlatformPicker
                      onChange={(value) => {
                        form.setFieldValue('platform', value);
                        form.setFieldValue('script_type', platformWiseScripts[value][0].value);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) =>
                      getFieldValue('platform') ? (
                        <Form.Item
                          label={type === 'command' ? 'Command Type' : 'Script Type'}
                          name="script_type"
                          dependencies={['platform']}
                          rules={[{ required: true }]}>
                          <Select
                            placeholder="Select Script Type"
                            options={
                              form.getFieldValue('platform')
                                ? platformWiseScripts[form.getFieldValue('platform').toLowerCase()]
                                : []
                            }
                          />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                </Col>
              </Row>
              <div className="flex-1">
                <Form.Item
                  label={`${type === 'command' ? 'Command' : 'Script'} Description`}
                  name="message"
                  rules={[{ required: true }]}>
                  <Input.TextArea
                    placeholder="Type ex. Write me a worklet for Windows that uninstall Adobe Flash."
                    rows={15}
                  />
                </Form.Item>
              </div>
              <div className="text-right mt-2">
                <Button
                  htmlType="submit"
                  type="primary"
                  className="gradient-btn"
                  loading={processing || eventInProgress}>
                  <Icon name="ai" className="text-lg" />
                  Generate
                </Button>
              </div>
            </div>
          </Form>
        </Col>
        <Col span={16} className="h-full">
          <div className="h-full flex flex-col min-h-0 flex-1">
            <h3>Result</h3>
            <Divider className="my-1" />
            <div className="flex flex-1 min-h-0 flex-col overflow-auto">
              {eventInProgress ? null : response ? (
                <ResponseRender
                  response={response}
                  onSelect={(value) => setSelectedScript((prev) => ({ ...prev, ...value }))}
                  selectedScript={selectedScript}
                  onChange={setModifiedScript}
                  scriptType={formItem.script_type}
                />
              ) : (
                <div className="flex flex-col items-center justify-center h-full">
                  <h3 className="text-neutral-light italic">
                    Please describe what should the script do
                  </h3>
                </div>
              )}
              {eventKey > 0 ? (
                <>
                  <SocketEventHandler
                    event="ai_request"
                    key={eventKey}
                    context={formItem}
                    onDataReceived={handleResponseReceived}
                  />
                </>
              ) : null}
            </div>
            <div className="text-right mt-2">
              {selectedScript ? (
                type === 'script' ? (
                  <Popover
                    title="Save Generated Script"
                    content={
                      <Form layout="vertical" onFinish={handleSaveScript}>
                        <Form.Item
                          name="use_custom_name"
                          label="Provide Name For Script"
                          valuePropName="checked">
                          <Switch />
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldValue }) =>
                            getFieldValue('use_custom_name') ? (
                              <Form.Item
                                name="name"
                                label="Script Name"
                                rules={[{ required: true }]}>
                                <Input placeholder="Script Name" />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                        <div className="text-right">
                          <Button htmlType="submit">Save</Button>
                        </div>
                      </Form>
                    }
                    open={popConfirmOpen}
                    trigger={'click'}
                    destroyTooltipOnHide
                    onOpenChange={(value) => setPopConfirmOpen(value)}>
                    <Button
                      className="gradient-btn"
                      type="primary"
                      onClick={() => setPopConfirmOpen(true)}
                      loading={processing}>
                      Save Script
                    </Button>
                  </Popover>
                ) : (
                  <Button
                    className="gradient-btn"
                    type="primary"
                    onClick={() => {
                      onChange(selectedScript.script);
                      onClose();
                    }}
                    loading={processing}>
                    Use Selected Command
                  </Button>
                )
              ) : null}
              <Button onClick={onClose} className="ml-2">
                Cancel
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}
