import Merge from 'lodash/merge';
import FindIndex from 'lodash/findIndex';
import Omit from 'lodash/omit';
import getChartCommonOptions from './chart-common-options';
import { buildSeries } from '../result-builder';
import NumberFormat from '@utils/number-format';

export default function processChartOptions(data, widget, options) {
  options = Merge(options, { dateFormat: options.dateFormat || 'ddd, MMM D,YYYY hh:mm a' });
  const isStacked = options.stacked;
  const categories = options.categories;
  const dateTime = options.dateTime;
  const widgetProperties = widget.widgetProperties || {};
  const chartOptions = widgetProperties.chartOptions || {};
  const allowDecimal = options.allowDecimal;
  const enableLegend = options.enableLegend;
  const hideXAxis = options.hideXAxis;

  const commonOptions = getChartCommonOptions(
    buildSeries(data, {
      type: widget.type,
      dateTime: dateTime,
      isStacked,
      widgetProperties
    }),
    widgetProperties,
    {
      hasDateTime: dateTime,
      timezone: options.timezone,
      dateFormat: options.dateFormat || 'ddd, MMM D,YYYY hh:mm a',
      serverSideZoom: options.serverSideZoom,
      unit: options.unit,
      sharedTooltip: !options.disableSharedTooltip,
      outsideTooltip: options.tooltipOutSide
    }
  );

  const appliedOptions = Merge(
    commonOptions,
    {
      ...(widgetProperties.verticalLegend
        ? {
            legend: {
              ...options.legend,
              orient: 'vertical'
            }
          }
        : {})
    },
    {
      ...(enableLegend
        ? {
            legend: {
              show: true
            }
          }
        : {}),
      ...(dateTime
        ? {
            dataZoom: [
              {
                type: 'inside',
                xAxisIndex: 0,
                minSpan: 10,
                moveOnMouseMove: false,
                zoomOnMouseWheel: false
              }
            ]
          }
        : {}),
      xAxis: {
        ...(categories && !dateTime
          ? {
              type: 'category',
              data: categories
            }
          : {}),
        ...(dateTime
          ? {
              type: 'time',
              axisLabel: {
                formatter: {
                  year: '{yyyy}',
                  month: '{dd} {MMM}',
                  day: '{dd} {MMM}',
                  hour: '{HH}:{mm}',
                  minute: '{HH}:{mm}',
                  second: '{HH}:{mm}:{ss}',
                  millisecond: '{hh}:{mm}:{ss} {SSS}',
                  none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
                }
              }
            }
          : {}),
        ...(hideXAxis
          ? {
              show: false
            }
          : {})
      },
      yAxis: {
        type: 'value',
        ...(options.maxY
          ? {
              max: options.maxY,
              endOnTick: false
            }
          : {}),
        ...(allowDecimal
          ? {}
          : {
              minInterval: 1
            }),
        splitNumber: 2,
        ...(widgetProperties.yAxisFormatter
          ? {
              axisLabel: {
                formatter: widgetProperties.yAxisFormatter
              }
            }
          : {})
      }
    },
    {
      ...(widget.type === 'PieChart'
        ? {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              left: 'right',
              top: 'center',
              orient: 'vertical',
              formatter(name) {
                if (!name) {
                  return '';
                }
                const index = FindIndex((data[0] || {}).data || [], (i) => i.name === name);
                const formattedValues = data[0].formattedValues;
                if (formattedValues && formattedValues.length) {
                  return `${name}: ${formattedValues[index]}`;
                }
                return `${name}: ${NumberFormat(((data[0] || {}).data[index] || {}).value || 0)}`;
              }
            }
          }
        : {})
    }
  );
  let allOptions = Merge(appliedOptions, Omit(chartOptions, ['stacks']));

  if (widget.type === 'PieChart') {
    allOptions = Omit(allOptions, ['xAxis', 'yAxis']);
    allOptions.legend = Omit(allOptions.legend, ['itemGap']);
    allOptions.series = [
      {
        ...allOptions.series[0],
        ...(enableLegend || widgetProperties.legendEnabled ? { right: 150 } : {}),
        ...(widgetProperties.pieInnerSize
          ? {
              radius: [
                `${100 - parseInt(widgetProperties.pieInnerSize) + 20}%`,
                widgetProperties.pieInnerSize
              ]
            }
          : {}),
        label: {
          show: widgetProperties.pieDataLabelsEnabled,
          fontSize: '11px',
          fontWeight: 'normal',
          fontFamily: 'Nunito',
          textBorderColor: 'transparent',
          color: 'var(--page-text-color)',
          ...(widgetProperties.ellipsisEnabled
            ? {
                overflow: 'truncate'
              }
            : {})
        }
      }
    ];
  }
  if (widget.type === 'Sankey') {
    allOptions = Omit(allOptions, ['xAxis', 'yAxis', 'grid', 'legend']);
    // allOptions = {
    //   series: allOptions.series
    // };
    allOptions.tooltip = {
      ...Omit(allOptions.tooltip, []),
      trigger: 'item',
      triggerOn: 'mousemove'
    };
  }

  if (!allOptions.dataZoom) {
    allOptions = Omit(allOptions, 'toolbox');
  }

  return allOptions;
}
