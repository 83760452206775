import { useEffect, useState, memo } from 'react';
import { Button } from 'antd';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs/components/prism-core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CheckCircleOutlined } from '@ant-design/icons';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-powershell';
import 'prismjs/components/prism-batch';
import 'prismjs/components/prism-bash';
import 'prism-themes/themes/prism-one-dark.css';

export default memo(
  function ScriptRenderer({ children, language, selected, onSelect, onChange }) {
    const [isCopied, setIsCopied] = useState(false);
    const [code, setCode] = useState('');
    useEffect(() => {
      setCode(children);
      // eslint-disable-next-line
    }, []);
    const languageMap = {
      bash: Prism.languages.bash,
      powershell: Prism.languages.powershell,
      batch: Prism.languages.batch
    };
    function handleUpdate(code) {
      setCode(code);
      onChange(code);
    }
    return (
      <div
        className={`flex flex-col border-solid ${
          selected ? `border-primary` : 'border-border'
        } rounded px-4 py-2 cursor-pointer`}
        onClick={selected ? undefined : () => onSelect({ script: children, language: language })}>
        <div className={`flex justify-between text-primary ${selected ? '' : 'hidden'}`}>
          <CheckCircleOutlined className="text-xl" />
          <CopyToClipboard
            text={children}
            onCopy={() => {
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 3000);
            }}>
            <Button onClick={(e) => e.stopPropagation()}>
              {isCopied ? (
                <span>
                  <CheckCircleOutlined className="mr-1" /> Copied
                </span>
              ) : (
                'Copy'
              )}
            </Button>
          </CopyToClipboard>
        </div>
        <Editor
          value={code}
          onValueChange={handleUpdate}
          highlight={(code) => Prism.highlight(code, languageMap[language], language)}
          padding={10}
          textareaClassName="focus-visible:outline-none"
          className="text-base"
        />
      </div>
    );
  },
  function (oldProps, newProps) {
    return true;
  }
);
