import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, PieChart, SankeyChart } from 'echarts/charts';
// import components, all suffixed with Component
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  MarkLineComponent,
  DataZoomComponent,
  ToolboxComponent
} from 'echarts/components';
import {
  // CanvasRenderer
  SVGRenderer
} from 'echarts/renderers';

// Register the required components
echarts.use([
  // CanvasRenderer,
  DataZoomComponent,
  SVGRenderer,
  PieChart,
  LineChart,
  BarChart,
  SankeyChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  MarkLineComponent,
  ToolboxComponent
]);

export default function ChartRender({ options, ...props }) {
  return (
    <ReactEChartsCore
      {...props}
      echarts={echarts}
      option={options}
      notMerge={true}
      lazyUpdate={true}
      theme={'dark'}
    />
  );
}
