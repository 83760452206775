import dashboard from './modules/dashboard';
import auth from './modules/auth';
import Settings from './modules/settings';
import Inventory from './modules/inventory';
import compliance from './modules/compliance';
import vulnerability from './modules/vulnerability';
import Investigate from './modules/investigate';
import Reports from './modules/reports';
import Rootkit from './modules/rootkit';
import Alerts from './modules/alerts';
import Fim from './modules/fim';
import DeviceAutomation from './modules/device-automation';
import Patch from './modules/patch';
// import OKR from './modules/okr';

const availableModules = [
  auth(),
  dashboard(),
  compliance(),
  vulnerability(),
  Settings(),
  Inventory(),
  Investigate(),
  Reports(),
  Rootkit(),
  Alerts(),
  Fim(),
  DeviceAutomation(),
  Patch()
  // OKR()
];

export default availableModules;
