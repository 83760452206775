import { Tag, Select } from 'antd';
import Capitalize from 'lodash/capitalize';
import { CrudProvider } from '@/src/hooks/crud';
import { getAllDeploymentTasktsApi } from '../deployments';
import { User } from '@/src/components/pickers/UserPicker';
import { Asset } from '@/src/components/pickers/AssetPicker';
// import { Link } from 'react-router-dom';
import PackageLogo from './PackageLogo';
// import { Asset } from '@/src/components/pickers/AssetPicker';
import ExternalLink from '@components/common/ExternalLink';
import { useState } from 'react';

export default function Tasks({ deploymentId }) {
  const [selectedStatus, setselectedStatus] = useState('all');
  const statusOptions = [
    {
      value: 'all',
      label: 'All'
    },
    {
      value: 'failed',
      label: 'Failed'
    },
    {
      value: 'pending',
      label: 'Pending'
    },
    {
      value: 'success',
      label: 'Success'
    }
  ];

  const tagColorMap = {
    waiting: 'warning',
    initiated: 'processing',
    in_progress: 'processing',
    failed: 'error',
    cancelled: 'error',
    success: 'success'
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sortable: false
    },
    {
      title: 'Endpoint',
      dataIndex: 'endpoint',
      key: 'endpoint',
      width: '20%',
      render({ record }) {
        const url = `/inventory/endpoints/${record.endpoint}`;
        return (
          <ExternalLink to={url}>
            <Asset.Picker value={record.endpoint} disabled textOnly />
          </ExternalLink>
        );
      }
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      ellipsis: true,
      width: '30%',
      render({ record, view }) {
        const hasLink = ['success', 'failed'].includes(record.taskStatus);
        return (
          <div className="flex items-center">
            <PackageLogo package={record} style={{ width: '30px' }} className="mr-2" disabled />
            <div
              className={hasLink ? 'cursor-pointer text-primary' : ''}
              onClick={() => (hasLink ? view(record) : null)}>
              {record.displayName}
            </div>
          </div>
        );
      }
    },
    {
      title: 'Status',
      key: 'taskStatus',
      dataIndex: 'taskStatus',
      width: 150,
      align: 'center',
      sortable: false,
      render({ record }) {
        return (
          <Tag
            color={tagColorMap[record.taskStatus.toLowerCase()]}
            className="inline-flex items-center justify-center"
            style={{
              textAlign: 'center',
              textTransform: 'uppercase',
              minWidth: '80px'
            }}>
            {Capitalize((record.taskStatus || '').toLowerCase()).replaceAll('_', ' ')}
          </Tag>
        );
      }
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sortable: false,
      render({ record }) {
        return <User.Picker textOnly value={record.createdBy} disabled />;
      }
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedTime',
      key: 'updatedTime',
      type: 'datetime'
    },

    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      type: 'datetime'
    }
  ];

  return (
    <CrudProvider
      key={selectedStatus}
      columns={columns}
      resourceTitle="Deployment Task"
      disableFormScrolling
      disableColumnSelection
      hasSearch
      autoRefresh={10000}
      drawerTitle={(item) => `${item.displayName} Output`}
      beforeCreateSlot={() => (
        <>
          <Select
            options={statusOptions}
            placeholder="Select Operation"
            className="mr-2"
            value={selectedStatus}
            onChange={setselectedStatus}
            style={{ width: '150px' }}
          />
        </>
      )}
      fetchFn={(...args) => getAllDeploymentTasktsApi(...args, { deploymentId, selectedStatus })}
      formFields={(item) => (
        <div className="py-4">
          <div style={{ whiteSpace: 'pre-line' }}>{item.result}</div>
        </div>
      )}
    />
  );
}
