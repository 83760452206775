import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import generateId from '@/src/utils/id';
import { Socket } from '@components/SocketListener';
import Bus from '@utils/emitter';
import { useAuth } from '../hooks/auth';

export default function SocketEventHandler({
  children,
  event,
  context,
  mergePayload,
  onDataReceived
}) {
  const socket = Socket.useSocket();
  const uuid = generateId();
  const { user } = useAuth();
  const [payload, setPayload] = useState(null);

  const sendEvent = () => {
    socket.send(
      event,
      typeof context === 'function'
        ? { ...context(), uuid, userId: user.id }
        : { ...context, uuid, userId: user.id }
    );
  };

  const handleDataReceived = (response) => {
    if (response.uuid !== uuid) {
      return;
    }
    if (mergePayload) {
      setPayload((payload) => {
        if (onDataReceived) {
          onDataReceived([...(payload || []), ...response.result]);
        }
        return [...(payload || []), ...response.result];
      });
    } else {
      if (onDataReceived) {
        onDataReceived(response);
      }
      setPayload(response);
    }
  };

  useEffect(() => {
    const dashboardRefresh = () => {
      if (event === 'analytics') {
        sendEvent();
      }
    };
    Bus.on('refresh:dashboard', dashboardRefresh);

    return () => Bus.off('refresh:dashboard', dashboardRefresh);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    sendEvent();
    Bus.on(event, handleDataReceived);
    Bus.on('socket:connected', sendEvent);

    return () => {
      Bus.off(event, handleDataReceived);
      Bus.off('socket:connected', sendEvent);
    };
    // eslint-disable-next-line
  }, [context]);

  return payload ? (
    children ? (
      children(payload)
    ) : null
  ) : (
    <div className="flex flex-1 items-center justify-center">
      <Spin />
    </div>
  );
}
